import React from "react"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image-es5"

import "./hero-banner.scss"

export const HeroBanner = ({
  title,
  subtitle,
  description,
  backgroundImage,
  callToAction,
}) => {
  const { hero } = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "section-page-banner-short.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const backgroundStack = [hero.childImageSharp.fluid]

  if (backgroundImage && backgroundImage.imageFile) {
    backgroundStack.push(backgroundImage.imageFile.childImageSharp.fluid)
  }

  return (
    <div className="p-0 jumbotron hero-banner">
      <div className="p-4 p-sm-5">
        <h1 className="hero-banner-title">{title}</h1>

        {subtitle && <h2 className="hero-banner-subtitle">{subtitle}</h2>}

        <div
          className="hero-banner-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {callToAction && (
          <a
            className="lead btn btn-secondary btn-lg d-inline-block btn-border-color"
            href={callToAction.href}
            aria-label={callToAction.ariaLabel}
            role="button"
          >
            {callToAction.label}
          </a>
        )}
      </div>

      <BackgroundImage fluid={backgroundStack} />
    </div>
  )
}

HeroBanner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  callToAction: PropTypes.shape({
    href: PropTypes.string,
    ariaLabel: PropTypes.string,
    label: PropTypes.string,
  }),
}
